export const API_CONFIG = {
    GET: "get",
    POST: "post",
    PUT: "put",
    PATCH: "patch",
    DELETE: "delete",
    BASE_URL_DEV: "https://api.staging.theobituaryapp.com",
    BASE_URL_QA: "",
    BASE_URL_REL: "",
    BASE_URL_PROD: "https://proapi.theobituaryapp.com"
};

export const API_ROUTES = {
    login: "/api/login",
    dashboardDetails: "/api/dashboard/admin/detail",
    me: "/api/user/me",
    logout: "/api/logout",
    obituaryListing: "/api/obituary/admin/list",
    obituaryDetails: "/api/obituary/admin/{obituaryId}",
    updateObituary: "/api/obituary-admin",
    updateObituaryActions: "/api/obituary/action",
    funeralHomeLookup: "/api/funeralhome/lookup",
    citySearch: "/api/city/search",
    userListing: "/api/users",
    details: "/api/dashboard/admin/detail",
    userDetails: "/api/user/{id}/details",
    userObituaries: "/api/user/owned-obituary/{userId}",
    userCities: "/api/user/{id}/follow-cities",
    deactivateUser: "/api/user/{id}/action/{action}",
    setUserPassword: "/api/user/{id}/set-user-password",
    requestedObituaryListing: "/api/obituary/requested-list",
    report: "/api/obituary/report/list",
    updateReportStatus: "/api/obituary/report/{id}/action/{action}",
    obituaryImage: "/api/obituary/image",
    obituaryExpressions: "/api/obituary/{obituaryId}/expressions",
    expressionReport: "/api/report/expression-report",
    funeralHomeDistriburedReport: "/api/report/fh-distributed",
    statusDistribution: "/api/report/status-distributed",
    dateDistribution: "/api/report/date-distributed",
    obitNotifications: "/api/notification/admin-obits?Page={Page}&Limit={Limit}",
    obitNotificationCount: "/api/notification/admin-obit-count",
    reportNotifications: "/api/notification/admin-reports?Page={Page}&Limit={Limit}",
    reportNotificationCount: "/api/notification/admin-report-count",
    advertisersList: "/api/advertisement/company/list",
    advertisementsList: "/api/advertisement/list",
    advertiserDetails: "/api/advertisement/company/{id}",
    advertisementDetails: "/api/advertisement/{id}",
    saveAdvertiser: "/api/advertisement/company/save",
    saveAdvertisement: "/api/advertisement/save",
    cityDetails: "/api/followed-cities/group-count",
    premiumObituaryList: "/api/obituary/admin/premium-list",
    setIsPosted: "/api/obituary/admin/set-is-posted",
    claimedObituaryList: "/api/obituary/admin/claimed-obituaries",
    obituaryLinksList: "/api/obituary/requested-obituary-link/lookup",
    obituaryLinksAction: "/api/obituary/requested-obituary-link/action",
    claimedObituaryCount: "/api/obituary/admin/claimed/obituaries/count",
    contactUsList: "/api/contact-us/lookup",
    updateContactQueryStatus: "/api/contact-us/status-update",
    onThisDayList: "/api/on-this-day-list",
    createOnThisDay: "/api/on-this-day",
    updateOnThisDay: "/api/on-this-day/{id}",
    getOnThisDay: "/api/on-this-day/{id}",
    deleteOnThisDay: "/api/on-this-day/{id}"
};

export const CONTENT_TYPE = {
    JSON: "application/json",
    FORM_DATA: "multipart/form-data"
};

export const HTTP_STATUS = {
    UNAUTHORIZED: 401,
    OK: 200,
    CREATED: 201,
    PAYLOAD_TOO_LARGE: 413,
    SERVER_ERROR: 500
};

export const PAGE_SIZE = 10;
