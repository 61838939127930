import get from "lodash.get";
import * as lodashIsEmpty from "lodash.isempty";
import cloneDeep from "lodash/cloneDeep";
import { AUTHENTICATED_ROUTES, UNAUTHENTICATED_ROUTES } from "../routes/constant";

function toDeepCopy(obj) {
    return cloneDeep(obj);
}

function getLoginUrl() {
    return UNAUTHENTICATED_ROUTES.LOGIN;
}

function getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
}

function capitalizedString(value, placeholder = "--") {
    if (!value) {
        return placeholder;
    }
    let valueArray = value.split(" ");
    valueArray = valueArray.map((item) => {
        return item.charAt(0).toUpperCase() + item.slice(1);
    });
    return valueArray.join(" ");
}

function replaceNullWithPlaceholder(value, placeHolder = "--") {
    if (!value) {
        return placeHolder;
    }
    return value;
}

function getValue(...param) {
    return get(...param);
}

function redirectToLogin(loginUrl = getLoginUrl()) {
    let returnUrl = encodeURIComponent(window.location.pathname.replace(/[//]+/, "") + window.location.search);
    redirectTo(loginUrl + "?returnUrl=" + returnUrl);
}

function redirectTo(url) {
    window.location.href = url;
}

function redirectAlongReturnUrl(addNewObituaryURL) {
    let returnUrl = encodeURIComponent(window.location.pathname.replace(/[//]+/, "") + window.location.search);
    redirectTo(addNewObituaryURL + "?returnUrl=" + returnUrl);
}

function redirectToReturnUrl() {
    redirectTo(getUrlParameterByName("returnUrl") ? "/" + getUrlParameterByName("returnUrl") : utilService.defaultUrl);
}

function getUrlParameterByName(name) {
    name = name.replace(/[]/, "\\[").replace(/[]/, "\\]");
    const regexS = `[\\?&]${name}=([^&#]*)`;
    const regex = new RegExp(regexS);
    const results = regex.exec(window.location.search);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

function isEmpty(value) {
    return lodashIsEmpty(value);
}

function numberFormat(number, n, x, s, c) {
    const re = `\\d(?=(\\d{${x || 3}})+${n > 0 ? "\\D" : "$"})`;
    const num = number.toFixed(Math.max(0, ~~n));

    return (c ? num.replace(".", c) : num).replace(new RegExp(re, "g"), `$&${s || ","}`);
}

function toNumber(input, decimal = 2) {
    if (input && !isNaN(input)) {
        return numberFormat(input, decimal, 3);
    }
    return input;
}

function getQueryParams(query, param) {
    let params = new URLSearchParams(query);
    return params.get(param);
}

function getUrlParam(urlParam, search) {
    return new URLSearchParams(urlParam).get(search);
}

function getRoute(route, obj = {}) {
    if (Object.keys(obj).length) {
        let objectKeys = Object.keys(obj);
        objectKeys.forEach((item) => {
            route = route.replace(new RegExp(/:([\d\w])+/, "i"), (match) => {
                let formattedMatchedValue = match.slice(1);
                return obj[formattedMatchedValue];
            });
        });
        return route;
    }
    return route;
}
function createURL(str) {
    const index = str.toUpperCase().indexOf("HTTP");
    if (index === -1) {
        return `http://${str}`;
    }
    return str;
}
function createDynamicUrl(dynamicUrl, object) {
    for (const key in object) {
        dynamicUrl = dynamicUrl.replace(`{${key}}`, object[key]);
    }
    return dynamicUrl;
}

function normalizeNotificationList(data) {
    const { pages } = data;
    const dump = pages?.reduce((obj, ele) => {
        Object.keys(ele.Notifications).forEach((key) => {
            if (obj[key]) {
                obj[key] = [...obj[key], ...ele.Notifications[key]];
            } else {
                obj[key] = ele.Notifications[key];
            }
        });
        return obj;
    }, {});

    return dump;
}

function truncateText(text, wordCount) {
    if (!text || typeof text !== "string") {
        return "--";
    }
    const words = text.split(" ");
    if (words.length <= wordCount) {
        return text;
    }
    const truncated = words.slice(0, wordCount).join(" ");
    return `${truncated}...`;
}

export {
    getLoginUrl,
    toNumber,
    getValue,
    redirectToLogin,
    redirectTo,
    redirectToReturnUrl,
    redirectAlongReturnUrl,
    getUrlParameterByName,
    isEmpty,
    getQueryParams,
    getUrlParam,
    replaceNullWithPlaceholder,
    getRoute,
    capitalizedString,
    getKeyByValue,
    createDynamicUrl,
    createURL,
    toDeepCopy,
    normalizeNotificationList,
    truncateText
};

export const utilService = {
    defaultUrl: AUTHENTICATED_ROUTES.DASHBOARD,
    baseUrl: process.env.REACT_APP_BASE_URL,
    apiUrl: process.env.REACT_APP_API_URL,
    graphqlApiUrl: process.env.REACT_APP_GRAPHQL
};
