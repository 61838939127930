import { Col, Menu, Row } from "antd";
import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { ConfirmationModal } from "../../components";
import { MAIN_SIDE_BAR_OPTIONS } from "../../constants/Roles";
import { AUTHENTICATED_ROUTES } from "../../routes/constant";
import LocalStorageService from "../../services/local-storage.service";
import { getRoute, redirectToLogin } from "../../util/utils.service";
import SidebarLogo from "./SidebarLogo";

const SidebarContent = () => {
    let { themeType } = useSelector(({ settings }) => settings);
    let sideBarOptions = MAIN_SIDE_BAR_OPTIONS["Admin"];
    const { pathname } = useLocation();
    const { SubMenu, Item } = Menu;
    const [showLogoutModal, setShowLogoutModal] = useState(false);
    const renderSideBarOptions = useCallback((props) => {
        if (!props.sideBarOptions) {
            return;
        }
        const { upperMenu, lowerMenu } = sideBarOptions;
        return (
            <>
                {upperMenu.map((singleSidebarOption) => {
                    return (
                        <div key={singleSidebarOption.selectedOptionKey}>
                            {singleSidebarOption.subMenu ? (
                                <SubMenu
                                    key={singleSidebarOption.selectedOptionKey}
                                    icon={<i className={`gx-text-white icon ${singleSidebarOption.icon} `} />}
                                    title={
                                        <span className="montserrat-regular gx-text-white">
                                            {singleSidebarOption.text}
                                        </span>
                                    }
                                >
                                    {Array.isArray(singleSidebarOption.subMenu) &&
                                        singleSidebarOption.subMenu.map((singleSubMenu, indexTwo) => {
                                            return (
                                                <Item className="child-menu" key={singleSubMenu.selectedOptionKey}>
                                                    <Link
                                                        to={singleSubMenu.linkTo}
                                                        className="montserrat-regular gx-text-white"
                                                    >
                                                        <Row align="middle" className="gx-m-0">
                                                            <Col className="gx-ml-4">
                                                                <span>{singleSubMenu.text}</span>
                                                            </Col>
                                                            <Col>
                                                                <i className={`icon ${singleSubMenu.icon} `} />{" "}
                                                            </Col>
                                                        </Row>
                                                    </Link>
                                                </Item>
                                            );
                                        })}
                                </SubMenu>
                            ) : (
                                <Item key={singleSidebarOption.selectedOptionKey}>
                                    <Link to={singleSidebarOption.linkTo} className="montserrat-regular">
                                        <span className="montserrat-regular gx-text-white">
                                            <i className={`icon ${singleSidebarOption.icon} `} />
                                            <span>{singleSidebarOption.text}</span>
                                        </span>
                                    </Link>
                                </Item>
                            )}
                        </div>
                    );
                })}
                <div className="side-menu-bottom">
                    <Menu.Item key="logout">
                        <span
                            onClick={() => setShowLogoutModal(!showLogoutModal)}
                            className="montserrat-regular gx-text-white"
                        >
                            <i className="icon usd-logout" />
                            <span>LOGOUT</span>
                        </span>
                    </Menu.Item>
                </div>
            </>
        );
    }, []);

    let selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split("/")[0];
    let VIEW_REQUESTED = getRoute(AUTHENTICATED_ROUTES.VIEW_REQUESTED_OBITUARY, {
        id: ""
    });
    let VIEW_OBITUARY = getRoute(AUTHENTICATED_ROUTES.VIEW_OBITUARY, {
        id: ""
    });
    if (pathname.includes(VIEW_REQUESTED)) {
        selectedKeys = AUTHENTICATED_ROUTES.REQUESTED_OBITUARIES.replace("/", "");
    }
    if (pathname.includes(VIEW_OBITUARY)) {
        selectedKeys = AUTHENTICATED_ROUTES.OBITUARIES.replace("/", "");
    }

    return (
        <>
            {showLogoutModal && (
                <ConfirmationModal
                    visible={showLogoutModal}
                    onCancel={() => {
                        setShowLogoutModal(false);
                    }}
                    onSuccess={({ ok }) => {
                        if (ok) {
                            LocalStorageService.clear();
                            redirectToLogin();
                            setShowLogoutModal(false);
                        }
                    }}
                />
            )}
            <SidebarLogo />
            <div className="gx-sidebar-content sidebar-scroll custom-sidebar-scroll">
                <Menu
                    defaultOpenKeys={[defaultOpenKeys]}
                    selectedKeys={[selectedKeys]}
                    theme={"light"}
                    mode="inline"
                    className="custom-sidebar-scroll side-menu"
                >
                    {renderSideBarOptions({ sideBarOptions: sideBarOptions })}
                </Menu>
            </div>
        </>
    );
};

SidebarContent.propTypes = {};
export default SidebarContent;
