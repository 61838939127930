import { Col, Pagination, Row, Table } from "antd";
import PropTypes from "prop-types";
import React from "react";

import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import "./grid-view.less";

const GridView = (props) => {
    const handleOnChange = (page) => {
        const newPage = page + 1;
        return props?.onPaginate(newPage);
    };

    const handleOnChangeTable = (pagination, filters, sort) => {
        const { onSort, clearSort } = props;
        const { columnKey, order } = sort;
        if (order === "ascend") {
            onSort(columnKey, 1);
        } else if (order === "descend") {
            onSort(columnKey, 2);
        } else {
            clearSort();
        }
    };

    function itemRender(current, type, originalElement) {
        if (type === "prev") {
            return (
                <a>
                    <div className="prevBtn">
                        <ArrowLeftOutlined />
                    </div>
                </a>
            );
        }
        if (type === "next") {
            return (
                <a>
                    <div className="prevBtn">
                        <ArrowRightOutlined />
                    </div>
                </a>
            );
        }
        return originalElement;
    }

    return (
        <div className={props?.tableKey}>
            <Table
                loading={props?.isFetching}
                className="gx-table-header-color ant-table-border"
                rowKey={props?.rowKey}
                style={{
                    float: "center"
                }}
                columns={props.columns}
                tableLayout={props.tableLayout ? props.tableLayout : "fixed"}
                dataSource={props?.data}
                pagination={false}
                expandable={props.handleExpand}
                onChange={handleOnChangeTable}
            />

            {!props?.tabKey && !props?.isLoading ? (
                <Row className="no-margin ant-row-flex gx-p-0" justify="center" align="middle">
                    <Col sm={24} xs={24} md={12} xl={12} lg={12}>
                        <Pagination
                            itemRender={itemRender}
                            className="table-pagination"
                            defaultCurrent={1}
                            hideOnSinglePage={true}
                            pageSize={props.pageSize}
                            total={props?.totalCount}
                            showSizeChanger={false}
                            onChange={handleOnChange}
                            current={props.currentPage}
                        />
                    </Col>
                </Row>
            ) : null}
        </div>
    );
};

GridView.prototype = {
    columns: PropTypes.array,
    data: PropTypes.any,
    sort: PropTypes.any,
    rowKey: PropTypes.any
};

export default GridView;
