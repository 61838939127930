export const STORAGE_KEY = {
    USER_INFO: "USER_INFO",
    ADMIN_INFO: "ADMIN_INFO",
    TOKEN: "TOKEN",
    OBITUARIES: "OBITUARIES",
    OBITUARY_REQUEST: "OBITUARY_REQUEST",
    OBIT_DETAIL: "OBIT_DETAIL",
    USERS: "USERS",
    DETAILS: "DETAILS",
    REPORT: "REPORT",
    OBIT_NOTIFICATIONS: "OBIT_NOTIFICATIONS",
    REPORT_NOTIFICATIONS: "REPORT_NOTIFICATIONS",
    EXPRESSION_REPORT: "EXPRESSION_REPORT",
    FUNERAL_HOMES_DISTRIBUTED: "FUNERAL_HOMES_DISTRIBUTED",
    STATUS_DISTRIBUTION: "STATUS_DISTRIBUTION",
    ADVERTISERS: "ADVERTISERS",
    ADVERTISEMENTS: "ADVERTISEMENTS",
    ADVERTISER_DETAILS: "ADVERTISER_DETAILS",
    ADVERTISEMENT_DETAILS: "ADVERTISEMENT_DETAILS",
    CITY_DETAILS: "CITY_DETAILS",
    PREMIUM_OBITUARY: "PREMIUM_OBITUARY",
    CLAIMED_OBITUARY: "CLAIMED_OBITUARY",
    OBITUARY_LINKS: "OBITUARY_LINKS",
    CLAIMED_OBITUARY_COUNT: "CLAIMED_OBITUARY_COUNT",
    REMOVAL_REQUEST: "REMOVAL_REQUEST",
    ON_THIS_DAY: "ON_THIS_DAY"
};
